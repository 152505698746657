import novelaTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui';

export default {
  ...novelaTheme,
  colors: {
    ...novelaTheme.colors,
    gradient: "linear-gradient(180deg, rgba(217, 219, 224, 0) 0%, #fff 100%)",
    primary: '#000',
    secondary: '#73737D',
    accent: '#6166DC',
    grey: '#73737D',
    background: '#fff',
  },
};
